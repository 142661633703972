import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Grid,
    Box,
    Modal,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { GET_ROLES } from './Dashboard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const GET_DATA_BY_ROLE = gql`
    query GetDataByRole($eventId: ID!, $role: String!, $fromDate: String!, $toDate: String!) {
        getDataByRole(eventId: $eventId, role: $role, fromDate: $fromDate, toDate: $toDate)
    }
`;

const BULK_PRINT = gql`
    mutation bulkBadgePrinting($eventId: String!, $role: String!, $pageSize: String!, $ids: [String]!) {
        bulkBadgePrinting(eventId: $eventId, role: $role, pageSize: $pageSize, ids: $ids) {
            message
            filePath
        }
    }
`;

export default function BulkPrinting() {
    const { eventId } = useParams();
    const { control, handleSubmit } = useForm();
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedPageSize, setSelectedPageSize] = useState('792x612'); // Default page size
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [downloadLink, setDownloadLink] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);

    const pageSizes = [
        { value: '792x612', label: 'Letter Landscape (792x612)' },
        { value: '612x792', label: 'Letter Portrait (612x792)' },
        { value: '1008x612', label: 'Legal Landscape (1008x612)' },
        { value: '612x1008', label: 'Legal Portrait (612x1008)' },
        { value: '720x540', label: 'Note Landscape (720x540)' },
        { value: '540x720', label: 'Note Portrait (540x720)' },
        { value: '1224x792', label: 'Tabloid Landscape (1224x792)' },
        { value: '792x1224', label: 'Tabloid Portrait (792x1224)' },
        { value: '792x1224', label: 'Ledger Landscape (792x1224)' },
        { value: '1224x792', label: 'Ledger Portrait (1224x792)' },
        { value: '416x283', label: 'Postcard Landscape (416x283)' },
        { value: '283x416', label: 'Postcard Portrait (283x416)' },
        { value: '756x283', label: 'Executive Landscape (756x283)' },
        { value: '522x756', label: 'Executive Portrait (522x756)' },
        { value: '1191x842', label: 'A3 Landscape (1191x842)' },
        { value: '842x1191', label: 'A3 Portrait (842x1191)' },
        { value: '842x595', label: 'A4 Landscape (842x595)' },
        { value: '595x842', label: 'A4 Portrait (595x842)' },
        { value: '595x420', label: 'A5 Landscape (595x420)' },
        { value: '420x595', label: 'A5 Portrait (420x595)' },
        { value: '421x298', label: 'A6 Landscape (421x298)' },
        { value: '298x421', label: 'A6 Portrait (298x421)' },
        { value: '1000x708', label: 'B4 Landscape (1000x708)' },
        { value: '708x1000', label: 'B4 Portrait (708x1000)' },
        { value: '708x498', label: 'B5 Landscape (708x498)' },
        { value: '498x708', label: 'B5 Portrait (498x708)' }
    ];

    const { data: rolesData } = useQuery(GET_ROLES);
    const [fetchData] = useLazyQuery(GET_DATA_BY_ROLE, {
        onCompleted: (result) => {
            try {
                const parsedData = JSON.parse(result.getDataByRole);
                setData(parsedData.rows);
            } catch (error) {
                setError('Failed to parse data.');
            }
        },
        onError: (e) => setError(e?.message || 'Failed to fetch data.')
    });

    const [bulkPrinting] = useMutation(BULK_PRINT, {
        onCompleted: (data) => {
            setDownloadLink(data.bulkBadgePrinting.filePath);
            setModalOpen(false);
            setError(''); // Clear error on successful print
        },
        onError: (e) => setError(e?.message || 'Failed to modify roles.')
    });

    const roles = rolesData?.event?.roles?.split(',') || [];

    const onSubmit = (formData) => {
        fetchData({
            variables: {
                eventId,
                role: formData.role,
                fromDate: format(new Date(formData.fromDate), 'yyyy-MM-dd'),
                toDate: format(new Date(formData.toDate), 'yyyy-MM-dd')
            }
        });
    };

    const handleBulkPrinting = () => {
        if (selectedRows.length === 0) {
            setError('Please select at least one row to print.');
            return;
        }
        setModalOpen(true);
    };

    const handlePrintAll = () => {
        if (!selectedPageSize) {
            setError('Please select a page size.');
            return;
        }

        bulkPrinting({
            variables: {
                eventId,
                role: selectedRole,
                pageSize: selectedPageSize,
                ids: selectedRows
            }
        });
    };

    const handleDownload = () => {
        if (downloadLink) {
            window.open(downloadLink, '_blank');
        }
    };

    const handleSelectRow = (id) => {
        setSelectedRows((prev) => {
            if (prev.includes(id)) {
                return prev.filter((rowId) => rowId !== id);
            }
            return [...prev, id];
        });
    };

    const handleSelectAllRows = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data.map((row) => row.id)); // Assuming each row has a unique 'id'
        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        setCurrentPage(1); // Reset to first page
    };

    const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box p={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Select Role</InputLabel>
                                <Controller
                                    name="role"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            onChange={(e) => {
                                                setSelectedRole(e.target.value);
                                                field.onChange(e);
                                            }}
                                        >
                                            {roles.map((role) => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="fromDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        label="From Date"
                                        value={field.value}
                                        onChange={(date) => field.onChange(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="toDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        label="To Date"
                                        value={field.value}
                                        onChange={(date) => field.onChange(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                {data.length > 0 && (
                    <Box mt={2} sx={{ maxHeight: '60vh', overflow: 'auto' }}>
                        <TableContainer>
                            <Table stickyHeader={true}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <input
                                                type="checkbox"
                                                checked={selectedRows.length === data.length}
                                                onChange={handleSelectAllRows}
                                            />
                                        </TableCell>
                                        {data.length > 0 &&
                                            Object.keys(data[0]).map((key) => (
                                                <TableCell key={key}>{key.charAt(0).toUpperCase() + key.slice(1)}</TableCell>
                                            ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            hover
                                            onClick={() => handleSelectRow(row.id)}
                                            selected={selectedRows.includes(row.id)}
                                        >
                                            <TableCell padding="checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(row.id)}
                                                    onChange={() => handleSelectRow(row.id)}
                                                />
                                            </TableCell>
                                            {Object.values(row).map((value, index) => (
                                                <TableCell key={index}>{value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {selectedRows.length > 0 && (
                            <Box mt={2}>
                                <Button variant="contained" color="secondary" onClick={handleBulkPrinting}>
                                    Bulk Print
                                </Button>
                            </Box>
                        )}

                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100, 500, 1000, { label: 'All', value: data.length }]}
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage - 1}
                            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </Box>
                )}

                {error && (
                    <Typography variant="body1" py={2} color={'red'}>
                        {error}
                    </Typography>
                )}

                {downloadLink && (
                    <Box mt={2}>
                        <Button variant="contained" color="error" onClick={handleDownload}>
                            Download Bulk Printing PDF
                        </Button>
                    </Box>
                )}

                <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                    <Box p={4} bgcolor="background.paper" borderRadius={2} boxShadow={3}>
                        <Typography variant="h6" gutterBottom>
                            Bulk Printing
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel>Select Page Size</InputLabel>
                            <Select value={selectedPageSize} onChange={(e) => setSelectedPageSize(e.target.value)}>
                                {pageSizes.map((size) => (
                                    <MenuItem key={size.value} value={size.value}>
                                        {size.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box mt={2}>
                            <Button variant="contained" color="primary" onClick={handlePrintAll}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </LocalizationProvider>
    );
}
