import PropTypes from 'prop-types';

// material-ui
import { useTheme, useMediaQuery, Box, Drawer, LinearProgress, Typography } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import { gql, useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router';
import NavItem from './MenuList/NavItem';
import NavGroup from './MenuList/NavGroup';
import { GET_VERIFICATION_FEATURE } from 'Components/RoleEmailPage';
import React from 'react';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { IconLogout, IconUserCheck, IconUsers, IconQrcode } from '@tabler/icons'; // Combined icon imports
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import EmailIcon from '@mui/icons-material/Email';
import { SwitchAccount } from '@mui/icons-material';
// constant
const icons = {
    IconLogout,
    IconUserCheck,
    IconUsers,
    VerifiedUserIcon,
    EmailIcon,
    IconQrcode,
    HandshakeOutlinedIcon,
    SwitchAccount,
    LocalPrintshopIcon
};
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const { eventId } = useParams();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { data, loading, error } = useQuery(GET_ME);
    const [roles, setRoles] = React.useState([]);
    const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFY_ROLES, {
        variables: { eventId }
    });
    React.useEffect(() => {
        setRoles(featureData?.getverifyroles);
    }, [featureData]);

    const showVerification = (data?.me?.permissions?.includes('admin') ?? false) && Array.isArray(roles) && roles.length > 0;

    if (featureLoading) {
        return (
            <>
                <LinearProgress />
            </>
        );
    }
    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography>{error.message}</Typography>;
    }
    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <MenuList />
                    <RoleList />
                    <MenuCard />
                    {showVerification && <Verification eventId={eventId} />}
                    {data?.me?.permissions.includes('emailtracking') && <EmailTracking permissions={data?.me} />}
                    {data?.me?.permissions.includes('leadgen') && <LeadGeneration permissions={data?.me} />}
                    {data?.me?.permissions.includes('showsponsors') && <ShowSponsorSystem permissions={data?.me} />}
                    {data?.me?.permissions?.includes('crew') && <Crew />}
                    {data?.me?.permissions?.includes('role-switcher') && <RoleSwitcher />}
                    {data?.me?.permissions?.includes('bulkprinting') && <BulkPrinting />}

                    <LogOut />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                    <RoleList />
                    {!!showVerification && <Verification eventId={eventId} />}
                    {data?.me?.permissions.includes('emailtracking') && <EmailTracking permissions={data?.me} />}
                    {data?.me?.permissions.includes('leadgen') && <LeadGeneration permissions={data?.me} />}
                    {data?.me?.permissions.includes('showsponsors') && <ShowSponsorSystem permissions={data?.me} />}
                    {data?.me?.permissions?.includes('crew') && <Crew />}
                    {data?.me?.permissions?.includes('role-switcher') && <RoleSwitcher />}
                    {data?.me?.permissions?.includes('bulkprinting') && <BulkPrinting />}
                    <LogOut />
                    <MenuCard />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;

export function RoleList() {
    const { data, loading, error } = useQuery(GET_ROLES, {
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    if (loading) {
        return [];
    }

    if (error) {
        return [{ id: 'error', title: 'Error', type: 'item', url: '', target: false }];
    }

    const rolesData = data?.event?.roles?.split(',') ?? [];

    const item = {
        id: 'roles',
        title: 'Roles List',
        type: 'group',
        children: rolesData.map((item, i) => ({
            id: i,
            title: item.charAt(0).toUpperCase() + item.slice(1),
            type: 'item',
            url: `${item}/data`,
            icon: icons.IconUserCheck,
            breadcrumbs: false
        }))
    };

    const level = 1;

    return <NavGroup item={item} level={level} />;
}

function Crew() {
    const level = 3;
    const crew = {
        id: 'crew',
        title: 'Features',
        type: 'group',
        children: [
            {
                id: 'reception',
                title: 'Reception Users',
                type: 'item',
                url: 'crew/RECEPTION',
                icon: icons.IconUsers,
                breadcrumbs: false
            },
            {
                id: 'printer',
                title: 'Printer Users',
                type: 'item',
                url: 'crew/PRINTER',
                icon: icons.IconUsers,
                breadcrumbs: false
            },
            {
                id: 'entryGate',
                title: 'Entry Gate Scanner',
                type: 'item',
                url: 'crew/ENTRY_SCAN',
                icon: icons.IconUsers,
                breadcrumbs: false
            },
            {
                id: 'exitGate',
                title: 'Exit Gate Scanner',
                type: 'item',
                url: 'crew/EXIT_SCAN',
                icon: icons.IconUsers,
                breadcrumbs: false
            }
        ]
    };
    return <NavGroup item={crew} level={level} />;
}
function RoleSwitcher() {
    const roleSwitcher = {
        id: 'roleSwitcher',
        title: 'Role Switcher',
        type: 'group',
        children: [
            {
                id: 'roleSwitcher',
                title: 'Role Switch',
                type: 'item',
                url: 'roleSwitcher',
                icon: icons.SwitchAccount,
                breadcrumbs: false
            }
        ]
    };
    return <NavGroup item={roleSwitcher} />;
}
function BulkPrinting() {
    const bulkprinting = {
        id: 'bulkprinting',
        title: 'Bulk Printing',
        type: 'group',
        children: [
            {
                id: 'bulkprinting',
                title: 'Bulk Print',
                type: 'item',
                url: 'bulkprinting',
                icon: icons.LocalPrintshopIcon,
                breadcrumbs: false
            }
        ]
    };
    return <NavGroup item={bulkprinting} />;
}
function Verification({ eventId }) {
    const { data, loading, error } = useQuery(GET_VERIFY_ROLES, {
        variables: { eventId },
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    const verifyRoles = data?.getverifyroles || [];

    const children = verifyRoles.map((item, i) => ({
        id: i + 6,
        title: `Verify ${item.charAt(0).toUpperCase() + item.slice(1)}`,
        type: 'item',
        url: `verify/${item}`,
        icon: icons.VerifiedUserIcon,
        breadcrumbs: false
    }));

    const verification = {
        id: 'verification',
        title: 'Registration Verification',
        type: 'group',
        children: children
    };
    const level = 2;
    return <NavGroup item={verification} level={level} />;
}
function EmailTracking() {
    const emailTracking = {
        id: 'emailTracking',
        title: 'Email Tracking',
        type: 'group',
        children: [
            {
                id: 'emailTracking',
                title: 'Email Tracking',
                type: 'item',
                url: 'emailtracking',
                icon: icons.EmailIcon,
                breadcrumbs: false
            }
        ]
    };
    return <NavGroup item={emailTracking} />;
}
function LeadGeneration() {
    const leadGeneration = {
        id: 'leadGeneration',
        title: 'Lead Generation',
        type: 'group',
        children: [
            {
                id: 'leadGeneration',
                title: 'Lead Generation',
                type: 'item',
                url: 'leadgeneration',
                icon: icons.IconQrcode,
                breadcrumbs: false
            }
        ]
    };
    return <NavGroup item={leadGeneration} />;
}
function ShowSponsorSystem() {
    const showSponsors = {
        id: 'showSponsors',
        title: 'Sponsors List',
        type: 'group',
        children: [
            {
                id: 'showSponsors',
                title: 'Sponsors List',
                type: 'item',
                url: 'showsponsors',
                icon: icons.HandshakeOutlinedIcon,
                breadcrumbs: false
            }
        ]
    };
    return <NavGroup item={showSponsors} />;
}
function LogOut() {
    const logout = {
        id: 'logout',
        title: 'Logout',
        type: 'group',
        children: [
            {
                id: 'logout',
                title: 'Logout',
                type: 'item',
                url: 'logout',
                icon: icons.IconLogout,
                breadcrumbs: false
            }
        ]
    };
    const level = 4;
    return <NavGroup item={logout} level={level} />;
}

export const GET_ROLES = gql`
    query {
        event {
            roles
        }
    }
`;
export const GET_VERIFY_ROLES = gql`
    query ($eventId: String!) {
        getverifyroles(eventId: $eventId)
    }
`;
const GET_ME = gql`
    query {
        me {
            permissions
        }
    }
`;
