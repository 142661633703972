import { useEffect, useState } from 'react';

// material-ui
import {
    AppBar,
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    LinearProgress,
    Modal,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
    useMediaQuery,
    withStyles
} from '@mui/material';

// project imports
import RoleDataCard, { RoleFootfallCard, SingleChart, UniqueCountry } from './Default/RoleDataCard';
import PopularCard from './Default/PopularCard';
import TotalOrderLineChartCard from './Default/TotalOrderLineChartCard';
import TotalIncomeDarkCard from './Default/TotalIncomeDarkCard';
import TotalIncomeLightCard from './Default/TotalIncomeLightCard';
import TotalGrowthBarChart from './Default/TotalGrowthBarChart';
import { gridSpacing } from 'store/constant';
import { gql, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { Box, Stack } from '@mui/system';
import React from 'react';
import styled from '@emotion/styled';
import MainCard from 'ui-component/cards/MainCard';
import SkeletonTotalOrderCard from 'ui-component/cards/Skeleton/EarningCard';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { RoleFootfallChart, RoleRegChart } from './RoleRegistrationChart';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { IconRefresh, IconX } from '@tabler/icons';
import { DialogTitle } from '@mui/material';
import SingleDataCard from 'Components/SingleDataCard';
import TotalData from 'Components/TotalData';
import UniqueData from 'Components/UniqueData';
// ==============================|| DEFAULT DASHBOARD ||============================== //

export default function RegDashboard() {
    const [isLoading, setLoading] = useState(true);
    const [totalArray, setTotalArray] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [designationArray, setDesignationArray] = useState([]);
    const [totalSum, setTotalSum] = useState(0);
    const [sum, setSum] = useState(0);
    const [array, setArray] = useState([]);
    const [countryChart, setCountryChart] = useState([]);
    const [designationChart, setDesignationChart] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [countries, setCountries] = useState([]);
    const [value, setValue] = useState(0);
    const { data, loading, error } = useQuery(GET_ROLES, {
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });
    const theme = useTheme();

    const eventId = useParams();
    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) {
        return [];
    }

    if (error) {
        return [{ id: 'error', title: 'Error', type: 'item', url: '', target: false }];
    }

    const updateSums = (newValue) => {
        const valueToAdd = newValue.length === 0 ? 0 : Number(newValue);
        setTotalSum((prevTotalSum) => prevTotalSum + valueToAdd);
        setSum((prevSum) => prevSum + valueToAdd);
    };

    const updateArrays = (newArray) => {
        if (newArray.length > 0) {
            setTotalArray((prevTotalArray) => [...prevTotalArray, ...newArray]);
            setArray((prevArray) => [...prevArray, ...newArray]);
        }
    };

    const updateCountryArray = (newArray) => {
        setCountryArray((prevCountryArray) => [...prevCountryArray, ...newArray]);
        setDesignationArray((prevDesigArray) => [...prevDesigArray, ...newArray]);
    };

    const updateCountryCount = (newValue) => {
        setCountries((prevCountryCount) => prevCountryCount + parseInt(newValue));
    };

    const updateDesignationCount = (newValue) => {
        setDesignations((prevDesignationCount) => prevDesignationCount + parseInt(newValue));
    };

    const updateDesignationArray = (newArray) => {
        setDesignationArray((prevDesignationArray) => [...prevDesignationArray, ...newArray]);
    };

    const updateCountryChart = (newArray) => {
        setCountryChart((prevCountryChart) => [...prevCountryChart, ...newArray]);
    };

    const updateDesignationChart = (newArray) => {
        setDesignationChart((prevDesignationChart) => [...prevDesignationChart, ...newArray]);
    };

    const rolesData = data?.event?.roles?.split(',') ?? [];

    const uniqueCountries = [...new Set(countryArray)];
    const uniqueCountryCount = uniqueCountries.length;

    const uniqueDesignations = [...new Set(designationArray)];
    const uniqueDesignationCount = uniqueDesignations.length;

    const uniqueSet = new Set(countryArray);
    const uniqueArr = [...uniqueSet];
    const totalUnique = uniqueArr.length;
    const uniqueDesignation = new Set(designationArray);
    const uniqueDesigArr = [...uniqueDesignation];
    const totalUniqueDesig = uniqueDesigArr.length;

    return (
        <Grid spacing={gridSpacing} container>
            <Grid item xs={12}>
                <Box sx={{ bgcolor: 'background.paper', width: '100%', padding: 2 }}>
                    <Grid spacing={gridSpacing} container>
                        <Grid item lg={4} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                            <Stack direction="column" spacing={1}>
                                <SingleDataCard
                                    roles={'Total Registration'}
                                    sum={sum}
                                    number={array}
                                    isLoading={isLoading}
                                    link="registration"
                                />
                            </Stack>
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={1}>
                                <TotalData eventId={eventId.eventId} direction="Print" value="Total OnSite Print" link="print" />
                            </Stack>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={1}>
                                <UniqueData eventId={eventId.eventId} direction="Print" value="Unique OnSite Print" link="print" />
                            </Stack>
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={1}>
                                <TotalData eventId={eventId.eventId} direction="Entry" value="Total Entry Scan" link="scan" />
                            </Stack>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={1}>
                                <UniqueData eventId={eventId.eventId} direction="Entry" value="Unique Entry Scan" link="scan" />
                            </Stack>
                        </Grid>

                        <Grid item lg={4} md={6} sm={6} xs={12}>
                            <Stack direction="column" spacing={1}>
                                <SingleDataCard
                                    roles={'Total Country'}
                                    sum={totalUnique}
                                    number={countryChart}
                                    isLoading={isLoading}
                                    country={uniqueArr}
                                    link="popup"
                                    name="Country"
                                    rolesData={rolesData}
                                    final={countryArray}
                                    eventId={eventId.eventId}
                                />
                            </Stack>
                        </Grid>

                        {/* <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Stack direction="column" spacing={1}>
                                    <SingleDataCard
                                        roles={'Total Designation'}
                                        sum={totalUniqueDesig}
                                        number={designationChart}
                                        isLoading={isLoading}
                                        country={uniqueDesigArr}
                                        link="popup"
                                        name="Designation"
                                        rolesData={rolesData}
                                        final={designationArray}
                                        eventId={eventId.eventId}
                                    />
                                </Stack>
                            </Grid> */}

                        {rolesData.map((item, i) => (
                            <Grid item lg={4} md={6} sm={6} xs={12} key={i} sx={{ display: 'none' }}>
                                <RoleDataCard
                                    eventId={eventId.eventId}
                                    role={item}
                                    updateTotalSum={updateSums}
                                    updateTotalArray={updateArrays}
                                    isLoading={isLoading}
                                    unique="created"
                                />
                            </Grid>
                        ))}
                        {rolesData.map((item, i) => (
                            <Grid item lg={4} md={6} sm={6} key={i} xs={12} sx={{ display: 'none' }}>
                                <UniqueCountry
                                    eventId={eventId.eventId}
                                    role={item}
                                    updateTotalSum={updateCountryCount}
                                    updateTotalArray={updateCountryArray}
                                    chartData={updateCountryChart}
                                    isLoading={isLoading}
                                    unique="Country"
                                />
                            </Grid>
                        ))}
                        {rolesData.map((item, i) => (
                            <Grid item lg={4} md={6} key={i} sm={6} xs={12} sx={{ display: 'none' }}>
                                <UniqueCountry
                                    eventId={eventId.eventId}
                                    role={item}
                                    country={uniqueDesignation}
                                    updateTotalSum={updateDesignationCount}
                                    updateTotalArray={updateDesignationArray}
                                    chartData={updateDesignationChart}
                                    isLoading={isLoading}
                                    unique="Designation"
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

export const GET_ROLES = gql`
    query {
        event {
            roles
        }
    }
`;
export const GET_ME = gql`
    query {
        me {
            permissions
        }
    }
`;
export const GET_PRINTS_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: printsCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        staffCounts: staffPrintsCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        minTime: printsMinTime
        maxTime: printsMaxTime
    }
`;
export const GET_FOOTFALL_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: scanCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        staffCounts: staffScanCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        minTime: scanMinTime
        maxTime: scanMaxTime
    }
`;
export const GET_TOTAL_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: totalCount(min: $min, max: $max, role: $role) {
            role
            total
        }
    }
`;
