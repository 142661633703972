import { Card, CardContent, Typography, Link, Stack, Divider, Button } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery, gql } from '@apollo/client';

const GET_SCAN_DATA_BY_STAND_ID = gql`
    query GetScanDataByStandId($eventId: String!, $standId: String!) {
        ScanDataByStandId(eventId: $eventId, standId: $standId)
    }
`;

export default function LeadGenCard({ stand, type }) {
    const navigate = useNavigate();
    const { eventId } = useParams();

    const standId = stand?.id;

    const { loading, error, data } = useQuery(GET_SCAN_DATA_BY_STAND_ID, {
        variables: { eventId, standId }
    });

    const ScannedData = (data && data?.ScanDataByStandId && JSON.parse(data?.ScanDataByStandId)) || [];
    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                    <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                        {type === 'admin' ? `Company:` : `Name Of Staff:`}
                    </Typography>
                    <Typography variant="span" justifyContent={'end'}>
                        {type === 'admin' ? `${stand?.company}` : ` ${stand?.name}`}
                    </Typography>
                </Stack>
                <Divider />
                {type === 'admin' && (
                    <>
                        <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                            <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                                No Of Staffs:
                            </Typography>
                            <Typography variant="span" justifyContent={'end'}>
                                {(stand.staff && stand?.staff.length) > 0 ? stand?.staff.length : 0}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                            <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                                Total No of Scans:
                            </Typography>
                            <Typography variant="span" justifyContent={'end'}>
                                {ScannedData?.length}
                            </Typography>
                        </Stack>
                        <Divider />
                    </>
                )}
                <Stack spacing={2} mt={2} justifyContent={'center'}>
                    <Button variant={'contained'} color="secondary" onClick={() => navigate(`${stand.id}`, { state: { stand } })}>
                        View Data
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
}
