import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Card, CardContent, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import LeadGenCard from './LeadGenCard';
import LeadGenStaffCard from './LeadGenStaffCard';
import SponsorsRegCard from './SponsorsRegCard';
import { useState } from 'react';
import { Stack } from '@mui/system';
import { Backspace } from '@mui/icons-material';

const GET_SPONSORSREGISTERED_LIST = gql`
    query GetSponsorsRegisteredData($eventId: String!, $searchTerm: String) {
        getSponsorsRegisteredData(eventId: $eventId, searchTerm: $searchTerm)
    }
`;

function SearchBox({ onSearch }) {
    const [search, setSearch] = React.useState('');
    React.useEffect(() => {
        const t = setTimeout(() => onSearch(search), 500);
        return () => clearTimeout(t);
    }, [search]);
    return (
        <TextField
            sx={{ width: 300 }}
            label="Search here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                            <IconButton onClick={() => setSearch('')}>
                                <Backspace />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

export default function SponsorsReg() {
    const { eventId } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const { loading, error, data } = useQuery(GET_SPONSORSREGISTERED_LIST, {
        variables: { eventId, searchTerm }
    });
    const navigate = useNavigate();

    const sponsorsRegisteredData = (data && data?.getSponsorsRegisteredData && JSON.parse(data?.getSponsorsRegisteredData)) || [];

    console.log('sposnorRegistered data', sponsorsRegisteredData);

    return (
        <div>
            {/* <Typography variant="h2" gutterBottom textAlign="center" sx={{ marginBottom: '20px' }}>
                Sponsors Registered Data
            </Typography> */}
            <Stack
                direction={'row'}
                justifyContent="space-between"
                sx={{
                    // bgcolor: 'background.paper',
                    width: '100%',
                    mb: 5
                }}
            >
                <Typography variant="h1" textAlign={'center'}>
                    Sponsors Data
                </Typography>
                {<SearchBox onSearch={setSearchTerm} />}
            </Stack>
            <Grid container spacing={2} justifyContent="center">
                {sponsorsRegisteredData.length > 0 ? (
                    sponsorsRegisteredData
                        ?.sort((a, b) => (a.company > b.company ? 1 : -1))
                        .map((sponsorData, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={index}>
                                <SponsorsRegCard sponsorData={sponsorData} />
                            </Grid>
                        ))
                ) : (
                    <Typography textAlign="center">Oops! No data found</Typography>
                )}
            </Grid>
        </div>
    );
}
