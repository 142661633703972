import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { MenuItem, Select, FormControl, InputLabel, Button, Grid, Box, Modal, Typography, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import { GET_ROLES } from 'layout/MainLayout/Sidebar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as XLSX from 'xlsx';

export default function RoleSwitcher() {
    const { eventId } = useParams();
    const { control, handleSubmit, reset } = useForm();
    const [selectedRole, setSelectedRole] = React.useState('');
    const [newSelectedRole, setNewSelectedRole] = React.useState('');
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [error, setError] = React.useState('');
    const [failedMigrations, setFailedMigrations] = React.useState([]);

    const { data: rolesData, loading: rolesLoading } = useQuery(GET_ROLES, {
        onError: (e) => console.error(e)
    });

    const [fetchData, { data: fetchDataResult, refetch }] = useLazyQuery(GET_DATA_BY_ROLE, {
        onCompleted: (result) => {
            try {
                setError('');
                const parsedData = JSON.parse(result.getDataByRole);
                setData(parsedData.rows);
                const columnDefs = parsedData.columns.map((col) => ({
                    field: col,
                    headerName: col.charAt(0).toUpperCase() + col.slice(1),
                    width: 150
                }));
                setColumns(columnDefs);
            } catch (error) {
                setError('Failed to parse data.');
                console.error('Parsing error:', error);
            }
        },
        onError: (e) => {
            setError(e?.message || 'Failed to fetch data.');
            console.error('Fetch error:', e);
        }
    });

    const [modifyRole] = useMutation(MODIFY_ROLE, {
        onCompleted: (data) => {
            const result = JSON.parse(data.modifyRole);
            if (result.errors.length > 0 || result.duplicates.length > 0) {
                setError(`Duplicates: ${JSON.stringify(result.duplicates)} | Errors: ${JSON.stringify(result.errors)}`);
                setFailedMigrations(result.errors.concat(result.duplicates));
            } else {
                setError('Role modified successfully.');
                setFailedMigrations([]);
            }
            refetch();
        },
        onError: (e) => {
            console.error('Modification error:', e);
            setError(e?.message || 'Failed to modify roles.');
        }
    });

    const roles = rolesData?.event?.roles?.split(',') || [];

    const onSubmit = (formData) => {
        setError('');
        fetchData({
            variables: {
                eventId,
                role: formData.role,
                fromDate: format(new Date(formData.fromDate), 'yyyy-MM-dd'),
                toDate: format(new Date(formData.toDate), 'yyyy-MM-dd')
            }
        });
    };

    const handleModifyRole = () => {
        if (selectedRows.length === 0) {
            setError('Please select at least one row to modify.');
            return;
        }
        setModalOpen(true);
    };

    const handleChangeRole = () => {
        if (!newSelectedRole) {
            setError('Please select a new role to change to.');
            return;
        }

        modifyRole({
            variables: {
                eventId,
                role: selectedRole,
                newRole: newSelectedRole,
                selectedRows
            }
        });

        setModalOpen(false);
    };

    const downloadFailedMigrations = () => {
        if (failedMigrations.length === 0) {
            setError('No failed migrations to download.');
            return;
        }

        const ws = XLSX.utils.json_to_sheet(failedMigrations);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Failed Migrations');
        XLSX.writeFile(wb, 'failed_migrations.xlsx');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box p={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <InputLabel>Select Role</InputLabel>
                                <Controller
                                    name="role"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            onChange={(e) => {
                                                setSelectedRole(e.target.value);
                                                field.onChange(e);
                                            }}
                                        >
                                            {roles.map((role) => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="fromDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        label="From Date"
                                        value={field.value}
                                        onChange={(date) => field.onChange(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="toDate"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        label="To Date"
                                        value={field.value}
                                        onChange={(date) => field.onChange(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </form>

                {data.length > 0 && (
                    <Box mt={4}>
                        <DataTable data={data} columns={columns} onSelectRows={(rows) => setSelectedRows(rows)} />
                        {selectedRows.length > 0 && (
                            <Box mt={2}>
                                <Button variant="contained" color="secondary" onClick={handleModifyRole}>
                                    Modify Role
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
                {error && (
                    <Typography variant="body1" py={2} color={'red'}>
                        {error}
                    </Typography>
                )}

                {failedMigrations.length > 0 && (
                    <Box mt={2}>
                        <Button variant="contained" color="error" onClick={downloadFailedMigrations}>
                            Download Failed Migrations
                        </Button>
                    </Box>
                )}

                <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                    <Box p={4} bgcolor="background.paper" borderRadius={2}>
                        <Typography variant="h6" gutterBottom>
                            Modify Role
                        </Typography>
                        <FormControl fullWidth>
                            <InputLabel>Select New Role</InputLabel>
                            <Select value={newSelectedRole} onChange={(e) => setNewSelectedRole(e.target.value)}>
                                {roles
                                    .filter((role) => role !== selectedRole)
                                    .map((role) => (
                                        <MenuItem key={role} value={role}>
                                            {role}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <Box mt={2}>
                            <Button variant="contained" color="primary" onClick={handleChangeRole}>
                                Proceed
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </LocalizationProvider>
    );
}

function DataTable({ data, columns, onSelectRows }) {
    const [selectedRows, setSelectedRows] = React.useState([]);

    const handleRowSelection = (newSelection) => {
        setSelectedRows(newSelection);
        onSelectRows(newSelection);
    };

    return (
        <Box height={400} width="100%">
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={100}
                checkboxSelection
                onSelectionModelChange={handleRowSelection}
                selectionModel={selectedRows}
            />
        </Box>
    );
}

const GET_DATA_BY_ROLE = gql`
    query GetDataByRole($eventId: ID!, $role: String!, $fromDate: String!, $toDate: String!) {
        getDataByRole(eventId: $eventId, role: $role, fromDate: $fromDate, toDate: $toDate)
    }
`;

const MODIFY_ROLE = gql`
    mutation ModifyRole($eventId: String!, $role: String!, $newRole: String!, $selectedRows: [ID!]!) {
        modifyRole(eventId: $eventId, role: $role, newRole: $newRole, selectedRows: $selectedRows)
    }
`;
