import { useQuery } from '@apollo/client';
import { LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import gql from 'graphql-tag';

export default function UniqueDataPopup({ countryCounts, name, rolesData, eventId, country }) {
    const { data, loading, error, refetch } = useQuery(GET_UNIQUE_DATA, {
        variables: {
            eventId,
            roleData: rolesData,
            uniqueCountries: country,
            conditions: {
                unique: name,
                orderBy: 'created',
                order: 'desc'
            }
        },
        skip: country.length === 0
    });

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography>{error.message}</Typography>;
    }

    return <DataTable data={data?.getUniqueCount} headerName={name} />;
}

function DataTable({ data, headerName }) {
    const columns = [
        { id: 'no', label: 'No.' },
        { id: 'data', label: `${headerName}` },
        ...data.reduce((acc, item) => {
            acc.push({ id: item.role, label: item.role });
            return acc;
        }, [])
    ];

    const rows = [];
    let count = 1;
    const countryCountMap = new Map();

    data.forEach((item) => {
        item.countryCounts.forEach((countryCount) => {
            let countryCountObj = countryCountMap.get(countryCount.country);
            if (!countryCountObj) {
                countryCountObj = { country: countryCount.country, count: 0 };
                countryCountMap.set(countryCount.country, countryCountObj);
            }
            countryCountObj[item.role] = countryCount.count;
            countryCountObj.count += countryCount.count;
        });
    });

    countryCountMap.forEach((countryCount, country) => {
        const row = { no: count++, data: countryCount.country };
        data.forEach((item) => {
            row[item.role] = countryCount[item.role] || 0;
        });
        rows.push(row);
    });

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id}>{column.label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.no}>
                                {columns.map((column) => (
                                    <TableCell key={column.id}>{row[column.id] || ''}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
export const GET_UNIQUE_DATA = gql`
    query ($eventId: String!, $roleData: [String], $uniqueCountries: [String], $conditions: InputConditions) {
        getUniqueCount(eventId: $eventId, roleData: $roleData, uniqueCountries: $uniqueCountries, conditions: $conditions) {
            role
            countryCounts {
                country
                count
            }
        }
    }
`;
