import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';
import VerificationPage from 'views/dashboard/VerificationPage';
import EmailTracker from 'views/dashboard/EmailTracker';
import LeadGen from 'views/dashboard/LeadGen';
import LeadGenLayout from 'layout/LeadGenLayout';
import LeadGenData from 'views/dashboard/LeadGenData';
import LeadGenDataLayout from 'layout/LeadGenLayout/LeadGenDataLayout.js';
import LeadGenStaffCard from 'views/dashboard/LeadGenStaffCard';
import ShowSponsorsListLayout from 'layout/ShowSponsorsListLayout';
import SponsorsReg from 'views/dashboard/SponsorsReg';
import SponsorRegList from 'views/dashboard/SponsorRegList';
import RoleSwitcher from 'views/dashboard/RoleSwitcher';
import BulkPrinting from 'views/dashboard/BulkPrinting';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard/Dashboard')));
const GateScanPage = Loadable(lazy(() => import('views/dashboard/GateScanPage')));
const PrintPage = Loadable(lazy(() => import('views/dashboard/PrintPage')));
const RegistrationForm = Loadable(lazy(() => import('views/dashboard/RegistrationForm')));
const RegDashboard = Loadable(lazy(() => import('views/dashboard/Dashboard')));
const DashLayout = Loadable(lazy(() => import('layout/DashLayout/index')));
const MinimalLayout = Loadable(lazy(() => import('layout/MinimalLayout/index')));
const Crew = Loadable(lazy(() => import('views/dashboard/Crew')));
const DataImportPage = Loadable(lazy(() => import('views/dashboard/DataImporter')));
const RegistrationPage = Loadable(lazy(() => import('views/dashboard/RegistrationPage')));
const LogoutPage = Loadable(lazy(() => import('views/pages/Logout')));
const RoleDataPage = Loadable(lazy(() => import('views/dashboard/RolePage')));
const RoleLayout = Loadable(lazy(() => import('layout/RoleLayout/index')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout/index')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '',
    element: <MinimalLayout />,
    children: [
        {
            path: '',
            element: <Navigate to="logout" replace />
        },
        {
            path: 'logout',

            element: <LogoutPage />
        },
        {
            path: ':eventId',
            element: <MainLayout />,
            children: [
                {
                    path: '',
                    element: <Navigate to="dashboard" replace />
                },
                {
                    path: 'dashboard',
                    element: <DashLayout />,
                    children: [
                        {
                            path: '',
                            element: <RegDashboard />
                        },
                        {
                            path: 'print',
                            element: <PrintPage />
                        },
                        {
                            path: 'registration',
                            element: <RegistrationPage />
                        },
                        {
                            path: 'scan',
                            element: <GateScanPage />
                        },
                        {
                            path: '*',
                            element: <LogoutPage />
                        }
                    ]
                },
                {
                    path: ':role',
                    element: <RoleLayout />,
                    children: [
                        {
                            path: '',
                            element: <Navigate to="data" replace />
                        },
                        {
                            path: 'data',
                            element: <RoleDataPage />
                        },
                        {
                            path: 'registration',
                            element: <RegistrationForm />
                        },
                        {
                            path: 'data-import',
                            element: <DataImportPage />
                        },
                        {
                            path: '*',
                            element: <LogoutPage />
                        }
                    ]
                },
                {
                    path: 'crew',
                    element: <Crew />
                },
                {
                    path: 'crew/RECEPTION',
                    element: <Crew />
                },
                {
                    path: 'roleSwitcher',
                    element: <RoleSwitcher />
                },
                {
                    path: 'bulkprinting',
                    element: <BulkPrinting />
                },
                {
                    path: 'crew/PRINTER',
                    element: <Crew />
                },
                {
                    path: 'crew/ENTRY_SCAN',
                    element: <Crew />
                },
                {
                    path: 'crew/EXIT_SCAN',
                    element: <Crew />
                },
                {
                    path: 'verify/:role',
                    element: <VerificationPage />
                },
                {
                    path: 'emailtracking',
                    element: <EmailTracker />
                },
                {
                    path: 'leadgeneration',
                    element: <LeadGenLayout />,
                    children: [
                        {
                            path: '',
                            element: <LeadGen />
                        },
                        {
                            path: ':exhibitorId',
                            element: <LeadGenDataLayout />,
                            children: [
                                {
                                    path: '',
                                    element: <LeadGenData />
                                },
                                {
                                    path: ':staffId',
                                    element: <LeadGenStaffCard />
                                },
                                {
                                    path: '*',
                                    element: <LogoutPage />
                                }
                            ]
                        },
                        {
                            path: '*',
                            element: <LogoutPage />
                        }
                    ]
                },
                {
                    path: 'showsponsors',
                    element: <ShowSponsorsListLayout />,
                    children: [
                        {
                            path: '',
                            element: <SponsorsReg />
                        },
                        {
                            path: ':sponsorId',
                            element: <SponsorRegList />
                        },
                        {
                            path: '*',
                            element: <LogoutPage />
                        }
                    ]
                },
                {
                    path: 'logout',
                    element: <LogoutPage />
                }
            ]
        },
        {
            path: '*',
            element: <LogoutPage />
        }
    ]
};

export default MainRoutes;
