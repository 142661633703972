import React from 'react';
import { useLocation, useParams } from 'react-router';
import LeadGenCard from './LeadGenCard';
import { Grid, Typography } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import LeadGenStaffCard from './LeadGenStaffCard';
import LeadGenDataTable from 'Components/LeadGenDataTable';
import { Box } from '@mui/system';

// Define the GraphQL query
const GET_SCAN_DATA_BY_STAND_ID = gql`
    query GetScanDataByStandId($eventId: String!, $standId: String!) {
        ScanDataByStandId(eventId: $eventId, standId: $standId)
    }
`;

export default function LeadGenData() {
    const location = useLocation();

    // Access the state passed during navigation
    const { state } = location;
    const standData = state && state.stand;
    const { exhibitorId, eventId } = useParams();

    const standId = exhibitorId;

    const { loading, error, data } = useQuery(GET_SCAN_DATA_BY_STAND_ID, {
        variables: { eventId, standId }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography variant="h2" gutterBottom textAlign="center" sx={{ marginBottom: '20px' }}>
                Lead Generation Data
            </Typography>
            {standData && standData.staff && standData.staff.length > 0 && (
                <Grid container spacing={2} justifyContent="center">
                    {standData?.staff?.map((staff, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                            <LeadGenCard stand={staff} type={'attendee'} />
                        </Grid>
                    ))}
                </Grid>
            )}
            <Grid spacing={2} container>
                <Grid item md={12}>
                    <LeadGenDataTable type={'admin'} id={standId} />
                </Grid>
            </Grid>
        </Box>
    );
}
