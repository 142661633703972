import { Dialog, DialogTitle, IconButton, styled } from '@mui/material';
import { IconX } from '@tabler/icons';
import MainCard from 'ui-component/cards/MainCard';
import PropTypes from 'prop-types';
const randomColors = ['#2c3e50', '#27ae60', '#34495e', '#3498db', '#e67e22', '#9b59b6', '#1abc9c', '#f1c40f', '#e74c3c', '#16a085'];
let usedColors = [];

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: selectRandomColor(),
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
        position: 'relative',
        zIndex: 5,
        transition: 'transform 0.3s ease-in-out'
    },
    '&>div:hover': {
        transform: 'scale(1.05) ',
        backgroundColor: selectRandomColor()
    },
    '&:hover': {
        backgroundColor: selectRandomColor()
    }
}));
export default CardWrapper;
function selectRandomColor() {
    let color = randomColors[Math.floor(Math.random() * randomColors.length)];
    while (usedColors.includes(color)) {
        color = randomColors[Math.floor(Math.random() * randomColors.length)];
    }
    usedColors.push(color);
    if (usedColors.length === randomColors.length) {
        usedColors = [];
    }
    return color;
}
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

export function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <IconX />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};
