import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

export function EmailSetup({ title, onSubmit, defaultValues, eventId, role }) {
    const [feature, setFeature] = React.useState(null);
    const { control, formState, handleSubmit, reset } = useForm({
        defaultValues: {
            senderName: '',
            senderEmail: '',
            senderEmailPassword: '',
            senderHost: '',
            senderSubject: '',
            senderBody: '',
            enquiryBody: '',
            to: ''
        }
    });
    const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
        variables: { eventId, role }
    });

    React.useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    React.useEffect(() => {
        if (featureData && featureData.mailVerificationFeature) {
            let parsedData = [];
            try {
                parsedData = JSON.parse(featureData.mailVerificationFeature);
            } catch (err) {
                console.error('Error parsing the data:', err);
            }
            if (Array.isArray(parsedData)) {
                setFeature(parsedData);
            }
        }
    }, [featureData]);
    if (featureLoading) {
        return (
            <>
                <span>Loading...</span>
            </>
        );
    }
    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} my={1}>
                <Typography fontWeight={'bold'}>{title}</Typography>
                <Stack spacing={1}>
                    <Controller
                        control={control}
                        name="senderName"
                        rules={{ required: 'Required' }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                            <TextField
                                inputRef={ref}
                                {...field}
                                required
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                                label="Sender Name"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        rules={{ required: 'Required' }}
                        name="senderEmail"
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                            <TextField
                                sx={{ display: 'none' }}
                                inputRef={ref}
                                {...field}
                                required
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                                label="Sender Email"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="senderEmailPassword"
                        rules={{ required: 'Required' }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                            <TextField
                                inputRef={ref}
                                sx={{ display: 'none' }}
                                {...field}
                                required
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                                label="Sender Email Password"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="senderHost"
                        rules={{ required: 'Required' }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                            <TextField
                                inputRef={ref}
                                sx={{ display: 'none' }}
                                {...field}
                                required
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                                label="Sender Host"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="senderSubject"
                        rules={{ required: 'Required' }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                            <TextField
                                inputRef={ref}
                                {...field}
                                required
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                                label="Subject"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="senderBody"
                        rules={{ required: 'Required' }}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                            <TextField
                                inputRef={ref}
                                {...field}
                                required
                                error={!!error}
                                helperText={error?.message}
                                fullWidth
                                label="Body"
                                multiline
                                minRows={4}
                            />
                        )}
                    />

                    <Divider />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <LoadingButton loading={formState.isSubmitting} type="submit" color="primary" variant="contained">
                        Send
                    </LoadingButton>
                    <Button color="error" onClick={() => reset()}>
                        Reset
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
}

export const GET_VERIFICATION_FEATURE = gql`
    query ($eventId: String!, $role: ROLE!) {
        mailVerificationFeature(eventId: $eventId, role: $role)
    }
`;
