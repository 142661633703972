import SkeletonTotalOrderCard from 'ui-component/cards/Skeleton/EarningCard';
import CardWrapper, { BootstrapDialog, BootstrapDialogTitle } from './RandomColorCard';
import { Box } from '@mui/system';
import { Button, DialogContent, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import UniqueDataPopup from './UniqueDataPopup';
import { IconRefresh } from '@tabler/icons';
import { SingleChart } from 'views/dashboard/Default/RoleDataCard';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import React from 'react';
export default function SingleDataCard({
    roles,
    sum,
    subRole,
    isLoading,
    number,
    link,
    final,
    name,
    rolesData,
    country,
    eventId,
    refetch
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const subRoleData = [{ name: 'Total', data: number }]; // data:[2,4,5,1,4]

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const navigates = (link) => {
        if (link === 'popup') {
            setOpen(true);
        } else {
            navigate(link);
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const countCountries = () => {
        const countMap = {};
        if (typeof final === 'undefined') {
            return [];
        }
        final.forEach((code) => {
            if (code !== '') {
                if (countMap[code]) {
                    countMap[code]++;
                } else {
                    countMap[code] = 1;
                }
            } else {
                if (countMap['Other']) {
                    countMap['Other']++;
                } else {
                    countMap['Other'] = 1;
                }
            }
        });

        const result = [];
        for (const code in countMap) {
            result.push({ name: code, count: countMap[code] });
        }
        return result;
    };

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const countryCounts = countCountries();
    return (
        <>
            {isLoading ? (
                <SkeletonTotalOrderCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <BootstrapDialog
                            onClose={handleClose}
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                            aria-labelledby="customized-dialog-title"
                            open={open}
                        >
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                {name} Detail
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <UniqueDataPopup
                                    countryCounts={countryCounts}
                                    name={name}
                                    rolesData={rolesData}
                                    country={country}
                                    eventId={eventId}
                                />
                            </DialogContent>
                        </BootstrapDialog>
                        <Grid container direction="column">
                            <Grid item sx={{ mb: 0.75 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                                    {sum || 0}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <IconButton onClick={() => refetch()}>
                                                    <IconRefresh color="white" stroke={1.5} size={'1.5rem'} />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: 500,
                                                        textTransform: 'capitalize',
                                                        color: 'white'
                                                    }}
                                                >
                                                    {roles}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} my={1}>
                                                <Button variant="outlined" color="inherit" onClick={() => navigates(link)}>
                                                    View All
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sx={{ marginY: '-15px' }}>
                                        <SingleChart subRole={subRoleData} number={number} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
}
