import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Grid, LinearProgress, Menu, MenuItem, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalOrderCard from 'ui-component/cards/Skeleton/EarningCard';

import ChartDataMonth from './chart-data/total-order-month-line-chart';
import ChartDataYear from './chart-data/total-order-year-line-chart';

// assets
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
const randomColors = ['#2c3e50', '#27ae60', '#34495e', '#3498db', '#e67e22', '#9b59b6', '#1abc9c', '#f1c40f', '#e74c3c', '#16a085'];
let usedColors = [];

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: selectRandomColor(),
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
        position: 'relative',
        zIndex: 5,
        transition: 'transform 0.3s ease-in-out'
    },
    '&>div:hover': {
        transform: 'scale(1.05) ',
        backgroundColor: selectRandomColor()
    },
    '&:hover': {
        backgroundColor: selectRandomColor()
    }
}));

function selectRandomColor() {
    let color = randomColors[Math.floor(Math.random() * randomColors.length)];
    while (usedColors.includes(color)) {
        color = randomColors[Math.floor(Math.random() * randomColors.length)];
    }
    usedColors.push(color);
    if (usedColors.length === randomColors.length) {
        usedColors = [];
    }
    return color;
}
const RoleDataCard = ({ isLoading, eventId, role, updateTotalSum, updateTotalArray, unique }) => {
    const theme = useTheme();

    const navigate = useNavigate();
    const [timeValue, setTimeValue] = useState(false);
    const handleChangeTime = (event, newValue) => {
        setTimeValue(newValue);
    };
    const value = '';
    const { data, loading, error, refetch } = useQuery(GET_STATS, {
        variables: {
            eventId,
            role,
            conditions: {
                unique,
                orderBy: 'created',
                order: 'desc'
            }
        }
    });
    const final = data?.getData || [];
    var date = [];
    var subRole = [];
    var number = [];
    var sum = Number(0);
    if (value === 'registration') {
        const dateObj = final.map((item) => item.date) || [];
        const dateString = dateObj.toString();
        if (typeof dateString === 'string') {
            const dateArray = dateString.split(',');
            date = dateArray.map((dateString) => dateString.substring(0, 15));
        }
        const subRoles = final.map((item) => JSON.parse(item.other));
        const flattenedSubRole = subRoles.reduce((acc, cur) => [...acc, ...cur], []);
        subRole = flattenedSubRole.map((item) => ({ name: item.name, data: item.data }));
        number = subRole.map((item) => item.data) || [];
        sum = number.reduce((acc, row) => acc + row.reduce((acc2, value) => acc2 + value, 0), 0);
    } else {
        number = final.map(({ total }) => total);
        sum = number.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0) || [];
        subRole = [{ name: role, data: number }];
    }
    useEffect(() => {
        updateTotalSum(sum);
        updateTotalArray(number);
    }, [sum]);
    return (
        <>
            {isLoading ? (
                <SkeletonTotalOrderCard />
            ) : (
                <CardWrapper onClick={() => navigate(`/${eventId}/${role}/data`)} sx={{ cursor: 'pointer' }} border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item sx={{ mb: 0.75 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography
                                                    sx={{
                                                        fontSize: '2.125rem',
                                                        color: 'white',
                                                        fontWeight: 500,
                                                        mr: 1,
                                                        mt: 1.75,
                                                        mb: 0.75
                                                    }}
                                                >
                                                    {Array.isArray(sum) ? 0 : sum}
                                                </Typography>
                                            </Grid>
                                            <Grid item></Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: 500,
                                                        color: 'white',
                                                        textTransform: 'capitalize'
                                                    }}
                                                >
                                                    {role}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SingleChart subRole={subRole} number={number} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};
export const UniqueCountry = ({ isLoading, eventId, role, updateTotalSum, updateTotalArray, unique, chartData }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [timeValue, setTimeValue] = useState(false);
    const handleChangeTime = (event, newValue) => {
        setTimeValue(newValue);
    };

    const { data, loading, error, refetch } = useQuery(GET_STATS, {
        variables: {
            eventId,
            role,
            conditions: {
                unique,
                orderBy: 'created',
                order: 'desc'
            }
        }
    });
    let value = '';
    const final = data?.getData || [];
    let date = [];
    let subRole = [];
    let number = [];
    let sum = 0;

    if (final && final.length) {
        if (value === 'registration') {
            const dateObj = final.map((item) => item.date) || [];
            const dateString = dateObj.toString();
            if (typeof dateString === 'string') {
                const dateArray = dateString.split(',');
                date = dateArray.map((dateString) => dateString.substring(0, 15));
            }
            const subRoles = final.map((item) => JSON.parse(item.other));
            const flattenedSubRole = subRoles.reduce((acc, cur) => [...acc, ...cur], []);
            subRole = flattenedSubRole.map((item) => ({ name: item.name, data: item.data }));
            number = subRole.map((item) => item.data) || [];
            sum = number.reduce((acc, row) => acc + row.reduce((acc2, value) => acc2 + parseInt(value, 10), 0), 0);
        } else {
            date = final.map((item) => item.date) || [];
            number = final.map(({ total }) => total);
            sum = number.reduce((acc, cur) => acc + parseInt(cur, 10), 0) || [];
            subRole = [{ name: role, data: number }];
        }
    }

    useEffect(() => {
        updateTotalSum(sum);
    }, [sum]);

    useEffect(() => {
        updateTotalArray(date);
        chartData(number);
    }, [sum]);

    return (
        <>
            {isLoading ? (
                <SkeletonTotalOrderCard />
            ) : (
                <CardWrapper onClick={() => navigate(`/${eventId}/${role}/data`)} sx={{ cursor: 'pointer' }} border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item sx={{ mb: 0.75 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>
                                                    {sum}
                                                </Typography>
                                            </Grid>
                                            <Grid item></Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    sx={{
                                                        fontSize: '1rem',
                                                        fontWeight: 500,
                                                        color: theme.palette.secondary[200],
                                                        textTransform: 'capitalize'
                                                    }}
                                                >
                                                    {role}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};
export const RoleFootfallCard = ({
    isLoading,
    eventId,
    role,
    title,
    count,
    query,
    updateTotalSum,
    updateTotalArray,
    direction,
    datafor
}) => {
    const theme = useTheme();
    const value = '';
    const navigate = useNavigate();
    const { data, loading, error, refetch } = useQuery(query, {
        variables: {
            role
        }
    });
    const [selectedStamp, setSelectedStamp] = React.useState(null);
    useEffect(() => {
        if (!selectedStamp) return;

        const t = setTimeout(() => {
            refetch({
                min: selectedStamp[0],
                max: selectedStamp[1]
            });
        }, 100);

        return () => {
            clearTimeout(t);
        };
    }, [selectedStamp]);

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography color="error">{(error.message, '')}</Typography>;
    }
    return (
        <>
            {isLoading ? (
                <SkeletonTotalOrderCard />
            ) : (
                <>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} direction="row" margin={2}>
                            {data?.counts.length === 0 ? (
                                ''
                            ) : (
                                <Typography
                                    sx={{
                                        fontSize: '2.125rem',
                                        fontWeight: 500,
                                        mr: 1,
                                        mt: 1.75,
                                        mb: 0.75,
                                        textAlign: 'center',
                                        textTransform: 'capitalize'
                                    }}
                                >{`${role} ${datafor} Detail`}</Typography>
                            )}
                        </Grid>
                        {data?.counts?.map((arr, i) => (
                            <Grid item xs={6} md={3} lg={2} direction="row" margin={2} key={i}>
                                <CardWrapper
                                    onClick={() => navigate(`/${eventId}/${role}/data`)}
                                    sx={{ cursor: 'pointer' }}
                                    border={false}
                                    content={false}
                                >
                                    <Box sx={{ p: 2.25 }}>
                                        <Grid container direction="row">
                                            <Grid item sx={{ mb: 0.75 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Grid container alignItems="center">
                                                            <Grid item>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '2.125rem',
                                                                        fontWeight: 500,
                                                                        mr: 1,
                                                                        mt: 1.75,
                                                                        mb: 0.75,
                                                                        color: 'white'
                                                                    }}
                                                                >
                                                                    {arr.total}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item></Grid>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '1rem',
                                                                        fontWeight: 500,
                                                                        color: 'white',
                                                                        textTransform: 'capitalize'
                                                                    }}
                                                                >
                                                                    {arr.role === role
                                                                        ? direction === 'Print'
                                                                            ? 'Total Print'
                                                                            : 'Total Scan'
                                                                        : direction === 'Print'
                                                                        ? `Total ${arr.role} Print`
                                                                        : `Total ${arr.role} Scan`}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardWrapper>
                            </Grid>
                        ))}

                        {data?.staffCounts?.map((arr, i) => (
                            <Grid item xs={6} md={3} lg={2} margin={2} direction="row" key={i}>
                                <CardWrapper
                                    onClick={() => navigate(`/${eventId}/${role}/data`)}
                                    sx={{ cursor: 'pointer' }}
                                    border={false}
                                    content={false}
                                >
                                    <Box sx={{ p: 2.25 }}>
                                        <Grid container direction="row">
                                            <Grid item sx={{ mb: 0.75 }}>
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <Grid container alignItems="center">
                                                            <Grid item>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '2.125rem',
                                                                        fontWeight: 500,
                                                                        mr: 1,
                                                                        mt: 1.75,
                                                                        mb: 0.75,
                                                                        color: 'white'
                                                                    }}
                                                                >
                                                                    {arr.total}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item></Grid>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '1rem',
                                                                        fontWeight: 500,
                                                                        color: theme.palette.secondary[200],
                                                                        textTransform: 'capitalize',
                                                                        color: 'white'
                                                                    }}
                                                                >
                                                                    {arr.role}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </CardWrapper>
                            </Grid>
                        ))}
                    </Grid>
                    {/*  */}
                </>
            )}
        </>
    );
};

RoleDataCard.propTypes = {
    isLoading: PropTypes.bool,
    eventId: PropTypes.string,
    role: PropTypes.string
};

export function SingleChart({ subRole, number }) {
    const chartData = {
        type: 'line',
        height: 90,
        options: {
            chart: {
                sparkline: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#fff'],
            fill: {
                type: 'solid',
                opacity: 1
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            yaxis: {
                min: 0,
                max: 1500
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    show: false
                },
                marker: {
                    show: false
                }
            }
        },
        series: subRole
    };
    return <Chart {...chartData} />;
}

export default RoleDataCard;
const GET_STATS = gql`
    query ($eventId: String!, $role: ROLE!, $conditions: InputConditions) {
        getData(eventId: $eventId, role: $role, conditions: $conditions) {
            date
            other
            total
        }
    }
`;
const GET_FOOTFALL_STATS = gql`
    query ($eventId: String!, $role: ROLE!, $value: String, $conditions: InputConditions) {
        getFootfallData(eventId: $eventId, role: $role, value: $value, conditions: $conditions) {
            date
            other
            total
        }
    }
`;
const GET_PRINTS_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: printsCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        staffCounts: staffPrintsCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        minTime: printsMinTime
        maxTime: printsMaxTime
    }
`;
const GET_FOOTFALL_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: scanCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        staffCounts: staffScanCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        minTime: scanMinTime
        maxTime: scanMaxTime
    }
`;
