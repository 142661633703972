import { Stack, Typography, Link as MuiLink, Divider, Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import React, { useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IconChartAreaLine } from '@tabler/icons';
import { IconClipboardData } from '@tabler/icons';
import { IconDatabaseImport } from '@tabler/icons';
// project imports
import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

function ShowSponsorsListLayout() {
    return (
        <React.Fragment>
            <Outlet />
        </React.Fragment>
    );
}

export default ShowSponsorsListLayout;
