import { LinearProgress } from '@mui/material';
import SingleDataCard from './SingleDataCard';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export default function UniqueData({ eventId, direction, value, link }) {
    const { data, loading, error, refetch } = useQuery(GET_UNIQUE_TOTAL, {
        variables: {
            eventId,
            direction,
            conditions: {
                orderBy: 'created',
                order: 'desc'
            }
        }
    });

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <SingleDataCard roles={value} sum={0} number={[0]} isLoading={loading} data={[]} link={link} refetch={refetch} />;
    }
    const final = data?.getUniqueTotal || []; // [{date:"",total:0, other:""}]

    // var date = [];
    var subRole = [];
    var number = [];
    var sum = final || 0;

    const sumArray = [];
    return (
        <SingleDataCard roles={value} sum={sum} number={sumArray || []} isLoading={loading} data={final} link={link} refetch={refetch()} />
    );
}

export const GET_UNIQUE_TOTAL = gql`
    query ($eventId: String!, $direction: String, $conditions: InputConditions) {
        getUniqueTotal(eventId: $eventId, direction: $direction, conditions: $conditions)
    }
`;
