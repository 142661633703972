import PropTypes from 'prop-types';
import { useState, useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Paper,
    Slider,
    Stack,
    Typography
} from '@mui/material';

// project imports
// import BajajAreaChartCard from './Default/BajajAreaChartCard';
import MainCard from '../../ui-component/cards/MainCard';
// import SkeletonPopularCard from '../../ui-component/cards/Skeleton/PopularCard';
import { gridSpacing } from '../../store/constant';

// assets
// import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
// import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
// import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { format } from 'date-fns';
import { IconRefresh } from '@tabler/icons';
import AnimateButton from 'ui-component/extended/AnimateButton';

// ==============================|| DASHBOARD DEFAULT - POPULAR CARD ||============================== //

const RolesDetailedData = ({ isLoading, role }) => {
    const theme = useTheme();

    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <MainCard content={false}>
                    <CardContent>
                        <Grid spacing={gridSpacing} container={true}>
                            <Grid item xs={12}>
                                <Grid container alignContent="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h3" textAlign={'center'}>
                                            Registration Detail
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <CountLargeCard title="Total Registrations" isRole={role} query={GET_ROLE_REG_COUNT} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};
export const FootfallDetailedData = ({ isLoading, role, currentPath }) => {
    const theme = useTheme();

    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <MainCard content={false}>
                    <CardContent>
                        {currentPath.pathname.includes('data') ? (
                            <Grid spacing={gridSpacing} container={true}>
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" textAlign={'center'}>
                                            Badge Print Detail
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <CountLargeCard title="Total Prints" isRole={role} query={GET_PRINTS_COUNT} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h3" textAlign={'center'}>
                                            Gate Scanner Detail
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <CountLargeCard title="Total Prints" isRole={role} query={GET_FOOTFALL_COUNT} />
                                    </Grid>
                                </>
                            </Grid>
                        ) : (
                            <Grid spacing={gridSpacing} container={true}>
                                {currentPath.pathname.includes('print') ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" textAlign={'center'}>
                                                Badge Print Detail
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CountLargeCard title="Total Prints" isRole={role} query={GET_PRINTS_COUNT} />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" textAlign={'center'}>
                                                Gate Scanner Detail
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CountLargeCard title="Total Prints" isRole={role} query={GET_FOOTFALL_COUNT} />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        )}
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};

RolesDetailedData.propTypes = {
    isLoading: PropTypes.bool
};

export default RolesDetailedData;
const RoleCount = ({ title, count }) => (
    <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
            <Typography variant="subtitle1" color="inherit" textTransform={'capitalize'}>
                {!title ? 'No Record Found' : title}
            </Typography>
        </Grid>
        <Grid item>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item mb={2}>
                    <Typography variant="subtitle1" color="inherit">
                        {count}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);
function RefreshButton({ loading, ...props }) {
    return (
        <Box
            sx={{
                position: 'relative'
                // right: 8,
                // top: 8
            }}
        >
            {!loading ? (
                <Paper
                    variant="outlined"
                    sx={{
                        p: 1
                    }}
                    component={ButtonBase}
                    {...props}
                >
                    <IconRefresh color="#5e35c0" stroke={1.5} size={'1.5rem'} />
                </Paper>
            ) : (
                <CircularProgress />
            )}
        </Box>
    );
}
CountLargeCard.propTypes = {
    query: PropTypes.object,
    isRole: PropTypes.string
};
function CountLargeCard({ query, title, isRole }) {
    const role = isRole;
    const { data, loading, error, refetch } = useQuery(query, {
        variables: {
            role
        }
    });
    const [selectedStamp, setSelectedStamp] = React.useState(null);
    React.useEffect(() => {
        if (!selectedStamp) return;

        const t = setTimeout(() => {
            refetch({
                min: selectedStamp[0],
                max: selectedStamp[1]
            });
        }, 100);

        return () => {
            clearTimeout(t);
        };
    }, [selectedStamp]);

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography color="error">{(error.message, 'No Data')}</Typography>;
    }
    return (
        <>
            <AnimateButton>
                <RefreshButton onClick={() => refetch()} loading={loading} />
            </AnimateButton>

            {data?.counts?.length === 0 ? (
                <Typography color={'red'} textAlign="center">
                    No Record Found
                </Typography>
            ) : (
                <Grid container direction="column">
                    {data?.counts?.map((arr, i) => (
                        <Grid item xs={12} md={12} lg={12} key={i}>
                            <RoleCount title={arr.role} count={arr.total} />
                        </Grid>
                    ))}

                    {data?.staffCounts?.map((arr, i) => (
                        <Grid item xs={12} md={12} key={i}>
                            <RoleCount title={arr.role} count={arr.total} />
                        </Grid>
                    ))}
                </Grid>
            )}
            <DateRangeSelector
                onChange={(v) => setSelectedStamp(v.map((a) => format(a, 'yyyy-MM-dd HH:mm:ss')))}
                minTime={+data?.minTime}
                maxTime={+data?.maxTime}
            />
        </>
    );
}

function DateRangeSelector({ minTime, maxTime, onChange }) {
    const [value, setValue] = React.useState([minTime, maxTime]);
    return (
        <Stack pt={2} pb={2} px={3}>
            {maxTime === 0 || minTime === 0 ? (
                <Typography color={'red'} variant="h5"></Typography>
            ) : (
                <Slider
                    min={minTime}
                    max={maxTime}
                    value={value}
                    onChange={(e, v) => setValue(v)}
                    onChangeCommitted={(e, v) => onChange(v)}
                    valueLabelFormat={(p) => format(p, 'dd-MM-yyyy hh:mm a')}
                    valueLabelDisplay="auto"
                    marks={[
                        { value: minTime, label: format(minTime, 'dd-MM-yyyy ') },
                        { value: maxTime, label: format(maxTime, 'dd-MM-yyyy ') }
                    ]}
                    sx={{
                        color: '#5e35c0',
                        '& .MuiSlider-thumb': {
                            borderRadius: '1px'
                        }
                    }}
                />
            )}
        </Stack>
    );
}
const GET_PRINTS_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: printsCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        staffCounts: staffPrintsCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        minTime: printsMinTime
        maxTime: printsMaxTime
    }
`;
const GET_ROLE_REG_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: regCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        staffCounts: linkRegCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        minTime: regMinTime
        maxTime: regMaxTime
    }
`;
const GET_FOOTFALL_COUNT = gql`
    query ($min: String, $max: String, $role: String) {
        counts: scanCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        staffCounts: staffScanCount(min: $min, max: $max, role: $role) {
            role
            total
        }
        minTime: scanMinTime
        maxTime: scanMaxTime
    }
`;
