import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, LinearProgress, MenuItem, Stack, TextField, Typography } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports ui-component/cards/Skeleton/TotalGrowthBarChart
import SkeletonTotalGrowthBarChart from '../../ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from '../../ui-component/cards/MainCard';
import { gridSpacing } from '../../store/constant';

// chart data
// import { chartData } from './RoleChartData';
import { gql, useQuery } from '@apollo/client';

// import { status } from './RoleChartData';
import { format } from 'date-fns';
import PopularCard from './Default/PopularCard';
import RolesDetailedData, { FootfallDetailedData } from './RolesDetailedData';
import { useLocation, useParams } from 'react-router';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //
export const status = [
    {
        value: 'registration',
        label: 'Registration'
    },
    {
        value: 'designation',
        label: 'Designation'
    },
    {
        value: 'country',
        label: 'Country'
    }
];
export const gateScanner = [
    {
        value: 'entry',
        label: 'Entry Gate'
    },
    {
        value: 'exit',
        label: 'Exit Gate'
    }
];
export const dataPage = [
    {
        value: 'print',
        label: 'Print Badge'
    },
    {
        value: 'entry',
        label: 'Entry Gate'
    },
    {
        value: 'exit',
        label: 'Exit Gate'
    }
];
export const onsitePrint = [
    {
        value: 'print',
        label: 'Print Badge'
    }
];
const chartTypeData = [
    { name: 'Line Graph', value: 'line' },
    { name: 'Area Graph', value: 'area' },
    { name: 'Bar Graph', value: 'bar' }
];

export default function RoleRegistrationChart() {
    return (
        <Stack direction="row" spacing={2}>
            <RoleRegChart />
            <RoleFootfallChart />
        </Stack>
    );
}

export const RoleRegChart = ({ isLoading, eventId, role }) => {
    const [value, setValue] = useState('registration');
    const [chartType, setChartType] = useState('area');
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);

    const { data, loading, error, refetch } = useQuery(GET_CHART_STATS, {
        variables: {
            eventId,
            role,
            conditions: {
                value,
                orderBy: 'created',
                order: 'desc'
            }
        }
    });

    const final = data?.getChartData || [];
    // console.log(final);
    var date = [];
    var subRole = [];
    var number = [];
    var sum = 0;

    if (value === 'registration') {
        const dateObj = final.map((item) => item.date) || [];
        const dateString = dateObj.toString();
        if (typeof dateString === 'string') {
            const dateArray = dateString.split(',');
            date = dateArray.map((dateString) => dateString.substring(0, 15));
        }
        const subRoles = final.map((item) => JSON.parse(item.other));
        const flattenedSubRole = subRoles.reduce((acc, cur) => [...acc, ...cur], []);
        subRole = flattenedSubRole.map((item) => ({ name: item.name, data: item.data }));
        number = subRole.map((item) => item.data) || [];
        sum = number.reduce((acc, row) => acc + row.reduce((acc2, value) => acc2 + value, 0), 0);
    } else {
        date = final.map((item) => item.other) || [];
        number = final.map(({ total }) => total);
        sum = number.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0) || [];
        subRole = [{ name: 'category', data: number }];
    }
    React.useEffect(() => {
        if (value === 'country' || value === 'designation') {
            setChartType('bar');
        } else if (value === 'registration') {
            setChartType('area');
        }
    }, [value]);
    // console.log(subRole, 'lkjhvg');

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard sx={{ my: 3 }}>
                    <Grid spacing={gridSpacing} container={true}>
                        <Grid item xs={12} md={9}>
                            <Typography variant="h1" textTransform={'capitalize'} textAlign={'center'}>
                                {role} Registration Chart
                            </Typography>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" textTransform="capitalize">
                                                Total {role} Registration
                                            </Typography>{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3">{sum}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Stack direction={'row'} spacing={2}>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                        >
                                            {status.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            value={chartType}
                                            onChange={(e) => setChartType(e.target.value)}
                                        >
                                            {chartTypeData.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <DataChart value={value} eventId={eventId} role={role} date={date} subRole={subRole} graph={chartType} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <RolesDetailedData role={role} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};
export const RoleFootfallChart = ({ isLoading, eventId, role }) => {
    const currentPath = useLocation();
    // const [currentDropdown, setCurrentDropdown] = React.useState([]);
    // if (currentPath.pathname.includes('scan')) {
    //     setCurrentDropdown(dataPage);
    // } else {
    //     setCurrentDropdown(onsitePrint);
    // }
    const [value, setValue] = useState(
        (currentPath.pathname.includes('scan') && 'entry') ||
            (currentPath.pathname.includes('print') && 'print') ||
            (currentPath.pathname.includes('data') && 'print')
    );
    const [chartType, setChartType] = useState('area');
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const { navType } = customization;

    const { data, loading, error, refetch } = useQuery(GET_FOOTFALL_STATS, {
        variables: {
            eventId,
            role,
            conditions: {
                value,
                orderBy: 'created',
                order: 'desc'
            }
        }
    });
    if (error) {
        return '';
    }
    const final = data?.getFootfallData || [];
    const isfinal = final.map((item) => JSON.parse(item.other));
    let isEmpty = true;

    for (let i = 0; i < isfinal.length; i++) {
        if (isfinal[i].length !== 0) {
            isEmpty = false;
            break;
        }
    }

    var date = [];
    var subRole = [];
    var number = [];
    var sum = 0;
    const dateObj = final.map((item) => item.date) || [];
    const dateString = dateObj.toString();
    if (typeof dateString === 'string') {
        const dateArray = dateString.split(',');
        date = dateArray.map((dateString) => dateString.substring(0, 15));
    }
    const subRoles = final.map((item) => JSON.parse(item.other));
    const flattenedSubRole = subRoles.reduce((acc, cur) => [...acc, ...cur], []);
    subRole = flattenedSubRole.map((item) => ({ name: item.name, data: item.data }));
    number = subRole.map((item) => item.data) || [];
    sum = number.reduce((acc, row) => acc + row.reduce((acc2, value) => acc2 + value, 0), 0);

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : !!error ? (
                'No Data Found'
            ) : (
                <MainCard sx={{ my: 2 }}>
                    <Grid spacing={gridSpacing} container={true}>
                        <Grid item xs={12} md={9} p={2}>
                            <Typography variant="h1" textTransform={'capitalize'} my={2} textAlign={'center'}>
                                {role} {currentPath.pathname.includes('print') ? 'OnSite Terminal ' : 'Gate Scanner'}
                            </Typography>

                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" mt={2} textTransform="capitalize">
                                                Total {role} Scan
                                            </Typography>{' '}
                                        </Grid>
                                        <Grid item>
                                            <Typography mb={2} variant="h3">
                                                {sum}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                        >
                                            {currentPath.pathname.includes('data')
                                                ? dataPage.map((option) => (
                                                      <MenuItem key={option.value} value={option.value}>
                                                          {option.label}
                                                      </MenuItem>
                                                  ))
                                                : currentPath.pathname.includes('scan')
                                                ? gateScanner.map((option) => (
                                                      <MenuItem key={option.value} value={option.value}>
                                                          {option.label}
                                                      </MenuItem>
                                                  ))
                                                : onsitePrint.map((option) => (
                                                      <MenuItem key={option.value} value={option.value}>
                                                          {option.label}
                                                      </MenuItem>
                                                  ))}
                                        </TextField>
                                        <TextField
                                            id="standard-select-currency"
                                            select
                                            value={chartType}
                                            onChange={(e) => setChartType(e.target.value)}
                                        >
                                            {chartTypeData.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {isEmpty ? (
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item md={12} spacing={2}>
                                        <Typography
                                            variant="h3"
                                            color={'red'}
                                            textTransform="capitalize"
                                            justifyContent={'center'}
                                            alignItems="center"
                                            marginTop={15}
                                        >
                                            No {value} Scan Found
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item md={12} spacing={2}>
                                        <FootfallChart
                                            value={value}
                                            eventId={eventId}
                                            role={role}
                                            date={date}
                                            subRole={subRole}
                                            graph={chartType}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FootfallDetailedData role={role} currentPath={currentPath} />
                        </Grid>
                    </Grid>
                </MainCard>
            )}
        </>
    );
};

// RoleRegistrationChart.propTypes = {
//     isLoading: PropTypes.bool,
//     eventId: PropTypes.string,
//     role: PropTypes.string,
//     data: PropTypes.string
// };

export function DataChart({ value, eventId, role, date, subRole, graph }) {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const { navType } = customization;
    const { primary } = theme.palette.text;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const deepTeal = '#004c6d';
    const coralPink = '#ff6b6b';
    const mustardYellow = '#ffc857';
    const lavenderPurple = '#9b59b6';
    const forestGreen = '#228c22';
    const burntOrange = '#ff7034';
    const skyBlue = '#87ceeb';
    const brickRed = '#a61c00';
    const slateGray = '#708090';
    const steelBlue = '#4682B4';
    const darkMagenta = '#8B008B';
    const oliveDrab = '#6B8E23';
    const sandyBrown = '#F4A460';
    const midnightBlue = '#191970';
    const goldenrod = '#dAA520';
    const darkCyan = '#008b8b';
    const oliveGreen = '#556b2f';
    const tomatoRed = '#ff6347';
    const peru = '#cd853f';
    const deepPink = '#ff1493';
    const chocolate = '#d2691e';

    const chartData = {
        height: 480,
        type: graph,
        options: {
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: false
                }
            },
            colors: [
                deepTeal,
                coralPink,
                mustardYellow,
                lavenderPurple,
                forestGreen,
                burntOrange,
                skyBlue,
                brickRed,
                slateGray,
                steelBlue,
                darkMagenta,
                oliveDrab,
                sandyBrown,
                midnightBlue,
                goldenrod,
                darkCyan,
                oliveGreen,
                tomatoRed,
                peru,
                deepPink,
                chocolate
            ],
            xaxis: { labels: { style: { colors: Array(12).fill(primaryDark) } } },
            yaxis: {
                labels: {
                    style: {
                        colors: [
                            deepTeal,
                            coralPink,
                            mustardYellow,
                            lavenderPurple,
                            forestGreen,
                            burntOrange,
                            skyBlue,
                            brickRed,
                            slateGray,
                            steelBlue,
                            darkMagenta,
                            oliveDrab,
                            sandyBrown,
                            midnightBlue,
                            goldenrod,
                            darkCyan,
                            oliveGreen,
                            tomatoRed,
                            peru,
                            deepPink,
                            chocolate
                        ]
                    }
                }
            },
            grid: { borderColor: grey200 },
            tooltip: { theme: 'dark' },
            legend: { labels: { colors: grey500 } },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],

            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            xaxis: {
                type: 'category',
                categories: date
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `'Roboto', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: true
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: true,
                offsetY: -3
            },
            grid: {
                show: true
            }
        },
        series: subRole
    };
    return <Chart {...chartData} />;
}
export function FootfallChart({ value, eventId, role, date, subRole, graph }) {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const { navType } = customization;
    const { primary } = theme.palette.text;
    const grey200 = theme.palette.grey[200];
    const grey500 = theme.palette.grey[500];
    const primaryDark = theme.palette.primary.dark;
    const secondaryMain = theme.palette.secondary.main;
    const deepTeal = '#004c6d';
    const coralPink = '#ff6b6b';
    const mustardYellow = '#ffc857';
    const lavenderPurple = '#9b59b6';
    const forestGreen = '#228c22';
    const burntOrange = '#ff7034';
    const skyBlue = '#87ceeb';
    const brickRed = '#a61c00';
    const slateGray = '#708090';
    const steelBlue = '#4682B4';
    const darkMagenta = '#8B008B';
    const oliveDrab = '#6B8E23';
    const sandyBrown = '#F4A460';
    const midnightBlue = '#191970';
    const goldenrod = '#dAA520';
    const darkCyan = '#008b8b';
    const oliveGreen = '#556b2f';
    const tomatoRed = '#ff6347';
    const peru = '#cd853f';
    const deepPink = '#ff1493';
    const chocolate = '#d2691e';

    const chartData = {
        height: 480,
        width: '100%',
        type: graph,
        options: {
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: false
                }
            },
            colors: [
                deepTeal,
                coralPink,
                mustardYellow,
                lavenderPurple,
                forestGreen,
                burntOrange,
                skyBlue,
                brickRed,
                slateGray,
                steelBlue,
                darkMagenta,
                oliveDrab,
                sandyBrown,
                midnightBlue,
                goldenrod,
                darkCyan,
                oliveGreen,
                tomatoRed,
                peru,
                deepPink,
                chocolate
            ],
            xaxis: { labels: { style: { colors: Array(12).fill(primaryDark) } } },
            yaxis: {
                labels: {
                    style: {
                        colors: [
                            deepTeal,
                            coralPink,
                            mustardYellow,
                            lavenderPurple,
                            forestGreen,
                            burntOrange,
                            skyBlue,
                            brickRed,
                            slateGray,
                            steelBlue,
                            darkMagenta,
                            oliveDrab,
                            sandyBrown,
                            midnightBlue,
                            goldenrod,
                            darkCyan,
                            oliveGreen,
                            tomatoRed,
                            peru,
                            deepPink,
                            chocolate
                        ]
                    }
                }
            },
            grid: { borderColor: grey200 },
            tooltip: { theme: 'dark' },
            legend: { labels: { colors: grey500 } },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],

            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            xaxis: {
                type: 'category',
                categories: date
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `'Roboto', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: true
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: true,
                offsetY: -3
            },
            grid: {
                show: false
            }
        },
        series: subRole
    };

    return <Chart {...chartData} />;
}

// export default RoleRegistrationChart;
const GET_STATS = gql`
    query ($eventId: String!, $role: ROLE!, $conditions: InputConditions) {
        getData(eventId: $eventId, role: $role, conditions: $conditions) {
            date
            other
            total
        }
    }
`;
const GET_CHART_STATS = gql`
    query ($eventId: String!, $role: ROLE!, $conditions: InputConditions) {
        getChartData(eventId: $eventId, role: $role, conditions: $conditions) {
            date
            other
            total
        }
    }
`;
const GET_FOOTFALL_STATS = gql`
    query ($eventId: String!, $role: ROLE!, $value: String, $conditions: InputConditions) {
        getFootfallData(eventId: $eventId, role: $role, value: $value, conditions: $conditions) {
            date
            other
            total
        }
    }
`;
DataChart.propTypes = {
    eventId: PropTypes.string,
    role: PropTypes.string,
    value: PropTypes.string
};
