import * as React from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Paper,
    TablePagination
} from '@mui/material';
import { useParams } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
export default function EmailTracker() {
    const [role, setRole] = React.useState(null);
    const { eventId } = useParams();

    const {
        data: featureData,
        loading: featureLoading,
        error: featureError
    } = useQuery(GET_VERIFY_ROLES, {
        variables: { eventId }
    });

    const [getSenderEmail, { data: senderEmail, loading: senderLoading, error: senderError }] = useLazyQuery(GET_SENDER_EMAIL);

    React.useEffect(() => {
        // if (featureData?.getverifyroles) {
        //     const fetchedRole = featureData.getverifyroles[0];
        //     setRole(fetchedRole);

        //     if (fetchedRole) {
        getSenderEmail({ variables: { eventId, role: 'visitor' } });
        //     }
        // }
    }, [featureData, getSenderEmail, eventId]);

    // Handle loading state
    if (featureLoading || senderLoading) {
        return <LinearProgress />;
    }

    // Handle errors
    if (featureError) {
        return <Typography>{featureError.message}</Typography>;
    }

    if (senderError) {
        return <Typography>{senderError.message}</Typography>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={2}>
                <MainCard>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                sx={{ bgcolor: 'background.paper', width: '100%', mb: 2 }}
                            >
                                <Typography variant="h1" textAlign="center" marginY={2}>
                                    Email Data
                                </Typography>
                                {senderEmail?.getSenderEmail && <DataTable senderEmail={senderEmail?.getSenderEmail} />}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Stack>
        </LocalizationProvider>
    );
}
function titleCase(str) {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

function DataTable({ senderEmail }) {
    const [search, setSearch] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const { data, loading, error, refetch } = useQuery(GET_EMAIL_DATA, {
        variables: { senderEmail }
    });
    React.useEffect(() => {
        if (data && data.getTrackingEmail) {
            const lowercasedSearch = search.toLowerCase();
            const filtered = data.getTrackingEmail
                .filter((row) => {
                    const createdDate = new Date(parseInt(row.created));
                    if (fromDate && createdDate < fromDate) return false;
                    if (toDate && createdDate > toDate) return false;
                    return Object.keys(row).some((key) => row[key] && row[key].toString().toLowerCase().includes(lowercasedSearch));
                })
                .sort((a, b) => b.created - a.created);
            setFilteredData(filtered);
        }
    }, [data, search, fromDate, toDate]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const eventStyles = {
        delivered: { background: '#78D237', color: 'white' },
        deliver: { background: '#78D237', color: 'white' },
        spam: { background: '#FBBC05', color: 'white' },
        bounced: { background: '#E37400', color: 'white' },
        bounce: { background: '#E37400', color: 'white' },
        unsubscribed: { background: '#D93025', color: 'white' },
        rejected: { background: '#5F6368', color: 'white' },
        reject: { background: '#5F6368', color: 'white' },
        open: { background: '#4285F4', color: 'white' },
        opened: { background: '#4285F4', color: 'white' },
        click: { background: '#A142F4', color: 'white' },
        clicked: { background: '#A142F4', color: 'white' },
        processed: { background: '#0F9D58', color: 'white' },
        process: { background: '#0F9D58', color: 'white' }
    };

    const exportToCSV = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const dataToExport = JSON.parse(JSON.stringify(data.getTrackingEmail));

        dataToExport.forEach((row) => {
            row.created = new Date(parseInt(row.created)).toLocaleString();
        });

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(dataBlob, 'email_tracking_data' + fileExtension);
    };

    if (loading) return <LinearProgress />;
    if (error) return <Typography color="error">{error.message}</Typography>;
    return (
        <Stack spacing={2} sx={{ position: 'relative', zIndex: 1 }}>
            <Box sx={{ maxHeight: '100vh', padding: 2 }}>
                <Stack direction="row" spacing={2}>
                    <TextField
                        label="Search"
                        sx={{ width: 500 }}
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <DatePicker
                        label="From Date"
                        value={fromDate}
                        onChange={setFromDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker label="To Date" value={toDate} onChange={setToDate} renderInput={(params) => <TextField {...params} />} />
                    <AnimateButton>
                        <Button variant="contained" disabled={loading} onClick={() => refetch()}>
                            Refresh
                        </Button>
                    </AnimateButton>
                    <AnimateButton>
                        <Button variant="contained" color="secondary" onClick={exportToCSV}>
                            Export
                        </Button>
                    </AnimateButton>
                </Stack>
            </Box>
            <Box sx={{ maxHeight: '80vh' }}>
                {' '}
                {/* Adjusted height to accommodate the footer */}
                {filteredData.length === 0 ? (
                    <Typography>No data found.</Typography>
                ) : (
                    <>
                        <TableContainer sx={{ maxHeight: '70vh', overflow: 'scroll' }} component={Paper}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ minWidth: '250px' }}>
                                            Recipients
                                        </TableCell>{' '}
                                        {/* Set maximum width for column */}
                                        <TableCell align="center" sx={{ minWidth: '330px' }}>
                                            Subject
                                        </TableCell>{' '}
                                        {/* Make the second column take full width */}
                                        <TableCell align="center">Event</TableCell>
                                        <TableCell align="left">URL</TableCell>
                                        <TableCell align="center" sx={{ minWidth: '200px' }}>
                                            Created
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                        <TableRow key={row.recipient + row.created + i}>
                                            <TableCell component="th" scope="row" align="left" sx={{ maxWidth: '300px' }}>
                                                {' '}
                                                {/* Set maximum width for column */}
                                                {row.recipient}
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: '100%' }}>
                                                {row.subject}
                                            </TableCell>{' '}
                                            {/* Make the second column take full width */}
                                            <TableCell align="center" style={eventStyles[row.event] || { background: 'transparent' }}>
                                                {titleCase(row.event)}
                                            </TableCell>
                                            <TableCell align="left">{row.url === 'N/A' ? '' : row.url}</TableCell>
                                            <TableCell align="center">{new Date(parseInt(row.created)).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={filteredData.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ marginBottom: 2 }}
                        />
                    </>
                )}
            </Box>
        </Stack>
    );
}

export const GET_EMAIL_DATA = gql`
    query ($senderEmail: String!) {
        getTrackingEmail(senderEmail: $senderEmail) {
            from_address
            recipient
            subject
            event
            reason
            url
            created
        }
    }
`;

export const GET_SENDER_EMAIL = gql`
    query ($eventId: String!, $role: ROLE!) {
        getSenderEmail(eventId: $eventId, role: $role)
    }
`;

const GET_VERIFY_ROLES = gql`
    query ($eventId: String!) {
        getverifyroles(eventId: $eventId)
    }
`;
