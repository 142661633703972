import { LinearProgress } from '@mui/material';
import SingleDataCard from './SingleDataCard';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

export default function TotalData({ eventId, direction, value, link }) {
    const { data, loading, error, refetch } = useQuery(GET_TOTAL, {
        variables: {
            eventId,
            direction,
            conditions: {
                orderBy: 'created',
                order: 'desc'
            }
        }
    });

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <SingleDataCard roles={value} sum={0} number={[0]} isLoading={loading} data={[]} link={link} refetch={refetch()} />;
    }
    const final = data?.getDirectionTotal || []; // [{date:"",total:0, other:""}]

    // var date = [];
    var subRole = [];
    var number = [];
    var sum = 0;

    const subRoles = final?.[0]?.other || '[]'; //map((item) => JSON.parse(item.other));
    const flattenedSubRole = JSON.parse(subRoles); //subRoles.reduce((acc, cur) => [...acc, ...cur], []);
    subRole = flattenedSubRole.map((item) => ({ name: 'Total', data: item?.data || [] }));
    number = subRole.map((item) => item.data) || [];
    sum = number.reduce((acc, row) => acc + row.reduce((acc2, value) => acc2 + value, 0), 0);
    // sum = subRole.reduce((a, c) => a + parseInt(c.total || 0), 0);

    const sumArray = subRole.reduce((acc, curr) => {
        return curr.data.map((num, i) => (acc[i] || 0) + num);
    }, []);

    // const sumArray = flattenedSubRole.map((v) => parseInt(v.total));

    const mergedArr = [].concat(...number);

    return <SingleDataCard roles={value} sum={sum} number={sumArray} isLoading={loading} data={final} link={link} refetch={refetch()} />;
}
export const GET_TOTAL = gql`
    query ($eventId: String!, $direction: String, $conditions: InputConditions) {
        getDirectionTotal(eventId: $eventId, direction: $direction, conditions: $conditions) {
            date
            other
            total
        }
    }
`;
