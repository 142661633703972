import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import LeadGenCard from './LeadGenCard';
import LeadGenData from './LeadGenData';
import { gql, useQuery } from '@apollo/client';
import LeadGenDataTable from 'Components/LeadGenDataTable';
import { Box } from '@mui/system';

const FETCH_SCAN_DATA_BY_ID = gql`
    query FetchScanDataById($eventId: String!, $id: String!) {
        fetchScanDataById(eventId: $eventId, id: $id)
    }
`;

export default function LeadGenStaffCard() {
    const navigate = useNavigate();
    const { exhibitorId, eventId, staffId } = useParams();
    const { loading, error, data } = useQuery(FETCH_SCAN_DATA_BY_ID, {
        variables: { eventId, id: staffId }
    });

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography variant="h2" gutterBottom textAlign="center" sx={{ marginBottom: '20px' }}>
                Staff Lead Generation Data
            </Typography>
            <Grid spacing={2} container>
                <Grid item md={12}>
                    <LeadGenDataTable type={'attendee'} id={staffId} />
                </Grid>
            </Grid>
        </Box>
    );
}
