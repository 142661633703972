import dashboard from './dashboard';
// import roles from './rolesexport';
// import roles from './roles';
// import logout from './logout';

// ==============================|| MENU ITEMS ||============================== //
// const roles = Roles();
// console.log(roles);
const menuItems = {
    items: [dashboard]
};

export default menuItems;
